* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Graphik", Arial, system-ui, -apple-system, blinkmacsystemfont,
    Segoe UI, roboto, ubuntu, Helvetica Neue, oxygen, cantarell, sans-serif;
}

body {
  background-color: rgb(242, 242, 242);
}

a {
  text-decoration: none;
}

.colorSuccess {
  color: #0ac241;
}

img {
  width: 100%;
}

.dFLex {
  display: flex;
  flex-wrap: wrap;
}

.alignItemCenter {
  align-items: center;
}

.col6 {
  width: 50%;
}

.col8 {
  width: 66.66%;
}
.col4 {
  width: 33.33%;
}

.ml1 {
  margin-left: 8px !important;
}
.mb1 {
  margin-bottom: 8px !important;
}
.mb2 {
  margin-bottom: 16px !important;
}

.py2 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.ps1 {
  padding-left: 8px;
}
.pe1 {
  padding-right: 8px;
}

.container {
  width: 90%;
  margin: 0 auto;
}
.maniContainer {
  width: 70%;
  margin: 0 auto;
}

.h100vh {
  height: 100vh;
}

.dFlex {
  display: flex;
  flex-wrap: wrap;
}

.textCenter {
  text-align: center;
}
.colorRed {
  color: red;
}

.jobContainer {
  background-image: url(../image/jobApplyImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.companyName {
  color: #fff;
  margin-left: 15px;
  padding-left: 15px;
  /* border-left: 1px solid #fff; */
}

.jobContainer::after {
  content: "";
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000080;
}

.jobForm {
  width: 600px;
  margin: auto;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  background-color: #fff;
  z-index: 1;
}

.jobForm h1 {
  margin-bottom: 10px;
}

.formInput {
  padding-top: 20px;
  position: relative;
}

.inputClose {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
}
.position-r {
  position: relative;
}
.formInput input {
  background-color: #fff;
}
.formLogo {
  margin: auto;
  width: 500px;
}
.formLogo img {
  width: 100%;
  height: 100%;
}
/* navbar start */
.navbar {
  padding: 15px 0;
  background-color: #0a75c2;
}
.logo {
  width: 100px;
}
.logo img {
  width: 100%;
  height: 100%;
}
/* navbar end */

.jobHeader {
  padding: 25px 0;
  background-color: rgb(242, 242, 242);
}
.jobHeader h1 {
  margin-bottom: 15px;
  font-size: 2.8rem;
}

.jobDescription {
  padding: 40px;
  background-color: #fff;
  border-radius: 5px;
}
.jobDescription p {
  line-height: 26px;
}
.jobLocation {
  padding: 30px 0;
}
.jobLocation span {
  font-weight: 600;
  font-size: 12px;
  padding: 0 20px;
}
.jobLocation span:last-child {
  border-left: 1px solid #575757;
}

.loader {
  width: 200px;
}
